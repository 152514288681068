document.addEventListener("turbolinks:load", function() {
	setTimeout(function(){$('.phano_photo').scrollLeft(620);}, 500);

	var validates_presence_list = document.getElementsByClassName("validate_presence");
	var validates_postcode_list = document.getElementsByClassName("validate_postcode");
	var validates_email_list = document.getElementsByClassName("validate_email");
	var validates_password_list = document.getElementsByClassName("validate_password");

	for (var i = 0; i < validates_presence_list.length; i++) {
		document.validate_presence(validates_presence_list[i]);
		validates_presence_list[i].addEventListener("keyup", document.validate_presence.bind(event));
	}

	for (var i = 0; i < validates_postcode_list.length; i++) {
		document.validate_is_postcode(validates_postcode_list[i]);
		validates_postcode_list[i].addEventListener("keyup", document.validate_is_postcode.bind(event));
	}

	for (var i = 0; i < validates_email_list.length; i++) {
		document.validate_is_email(validates_email_list[i]);
		validates_email_list[i].addEventListener("keyup", document.validate_is_email.bind(event));
	}
	for (var i = 0; i < validates_email_list.length; i++) {
		document.validate_is_email(validates_email_list[i]);
		validates_email_list[i].addEventListener("keyup", document.validate_is_email.bind(event));
	}
	for (var i = 0; i < validates_password_list.length; i++) {
		document.validate_is_good_password(validates_password_list[i]);
		validates_password_list[i].addEventListener("keyup", document.validate_is_good_password.bind(event));
	}
});
document.validate_presence = function(event){
	if (event.originalTarget == undefined){
		var element = event.id;
	}else{
		var element = event.originalTarget.id;
	}
	if ($("#" + element).val() == "" || $("#" + element).val() == undefined){
		$("#" + element).removeClass("valid");
		$("#" + element).addClass("invalid");
	}else{
		$("#" + element).removeClass("invalid");
		$("#" + element).addClass("valid");
	}
}
document.validate_is_postcode = function(event){
	if (event.originalTarget == undefined){
		var element = event.id;
	}else{
		var element = event.originalTarget.id;
	}
	if ($("#" + element).val().length >= 4 && $("#" + element).val().length <= 6){
		$("#" + element).removeClass("invalid");
		$("#" + element).addClass("valid");
	}else{
		$("#" + element).removeClass("valid");
		$("#" + element).addClass("invalid");
	}
}
document.validate_is_email = function(event){
	if (event.originalTarget == undefined){
		var element = event.id;
		var element_value = event.value
	}else{
		var element = event.originalTarget.id;
		var element_value = event.originalTarget.value
	}


	let emailFilter = /^[^@]+@[^@.]+\.[^@]*\w\w$/ ;
	let illegalChars= /[\(\)\<\>\,\;\:\\\"\[\]]/ ;

	if (!emailFilter.test(element_value)) {
		$("#" + element).addClass("invalid");
		$("#" + element).removeClass("valid");
	}else if (element_value.match(illegalChars) != undefined) {
		$("#" + element).addClass("invalid");
		$("#" + element).removeClass("valid");
	}else{
		$("#" + element).removeClass("invalid");
		$("#" + element).addClass("valid");
	}
}
document.validate_is_good_password = function(event){
	console.log("hello")

	if (event.originalTarget == undefined){
		var element = event.id;
		var element_value = event.value
	}else{
		var element = event.originalTarget.id;
		var element_value = event.originalTarget.value
	}

	let strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')

	if (strongPassword.test(element_value)) {
		$("#" + element).removeClass("invalid");
		$("#" + element).removeClass("medium");
		$("#" + element).addClass("valid");
	}else if(element_value.length >= 8){
		$("#" + element).removeClass("invalid");
		$("#" + element).addClass("medium");
		$("#" + element).removeClass("valid");
	} else {
		$("#" + element).addClass("invalid");
		$("#" + element).removeClass("medium");
		$("#" + element).removeClass("valid");
	}
}
