// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import jQuery from "jquery"

import { MultiFormatReader, BarcodeFormat, DecodeHintType, RGBLuminanceSource, BinaryBitmap, HybridBinarizer, BrowserBarcodeReader, BrowserQRCodeReader } from '@zxing/library';
global.MultiFormatReader = MultiFormatReader;
global.BarcodeFormat = BarcodeFormat;
global.DecodeHintType = DecodeHintType;
global.RGBLuminanceSource = RGBLuminanceSource;
global.BinaryBitmap = BinaryBitmap;
global.HybridBinarizer = HybridBinarizer;
global.BrowserBarcodeReader = BrowserBarcodeReader;
global.BrowserQRCodeReader = BrowserQRCodeReader;


//global.$ = require("jquery")

import "channels"
import "packs/code.js"

Rails.start()
Turbolinks.start()
ActiveStorage.start()
